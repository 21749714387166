<template>
	<b-row>
		<b-col cols="12">
			<custom-loading-planning :loading="isLoading" :label="'Project fimalac data'" />
			<b-card no-body class="card-border-blue-light">
				<b-row class="p-2">
					<b-col cols="8" class="d-flex flex-row gap-4">
						<div class="d-flex flex-grow-1">
							<b-form-input
								id="filterInput-project-fimalac"
								v-model="search"
								@keyup.enter="handleSearch"
								:placeholder="FormMSG(4, 'Enter project name to search')"
							></b-form-input>
							<b-tooltip target="filterInput-project-fimalac" placement="top" triggers="focus">
								{{ FormMSG(353, 'You must type more than 3 characters followed by enter to activate the search') }}
							</b-tooltip>
						</div>
						<div class="d-flex flex-grow-0">
							<b-button
								variant="none"
								:style="`width: 100px`"
								class="cursor-pointer d-inline-flex justify-content-center align-items-center"
								v-b-tooltip.hover.top
								:title="'Start to search'"
								style="border: 1px solid #00a09c; border-radius: 4px"
								@click="handleSearch"
							>
								<div class="pl-2" style="color: #00a09c">{{ FormMSG(5, 'Search') }}</div>
							</b-button>
						</div>
					</b-col>
					<b-col cols="4" class="d-flex flex-row align-items-center justify-content-end gap-4">
						<b-button variant="outline-primary" style="border-radius: 4px !important" @click.prevent="handleRefresh">
							<div class="d-flex justify-content-center align-items-center">
								<component :is="getLucideIcon('RefreshCcw')" color="#00A09C" :size="18" :stroke-width="2.25" />
								<div class="pl-2">{{ FormMSG(6, 'Refresh') }}</div>
							</div>
						</b-button>
					</b-col>
				</b-row>
				<b-row class="p-2 m-2 bg-beige">
					<b-col cols="12">
						<template>
							<div class="d-flex flex-row w-100 p-1">
								<div class="d-flex align-items-center justify-content-start w-25">{{ FormMSG(20, 'Artist') }}</div>
								<div class="d-flex align-items-center justify-content-start w-25">{{ FormMSG(8, 'Production') }}</div>
								<div class="d-flex align-items-center justify-content-start w-25">{{ FormMSG(9, 'Projet name') }}</div>
								<div class="d-flex align-items-center justify-content-end w-25">{{ FormMSG(10, 'Option') }}</div>
							</div>
						</template>
					</b-col>
				</b-row>
				<b-row class="p-2">
					<b-col cols="12">
						<template v-if="projects.length === 0">
							<div class="d-flex align-items-center justify-content-center w-100 text-muted fs-14 p-2">
								{{ FormMSG(11, 'No project active') }}
							</div>
						</template>
						<template v-else>
							<div id="list-of-content" class="d-flex flex-column" style="max-height: 650px; overflow-y: scroll">
								<div v-for="(item, index) of projects" :key="index">
									<div class="d-flex align-items-center w-100 p-1 hover-active" style="border-bottom: 1px solid #e2e6eb">
										<div class="d-flex align-items-center justify-content-start w-25">{{ item.name }}</div>
										<div class="d-flex align-items-center justify-content-start w-25">{{ item.production }}</div>
										<div class="d-flex align-items-center justify-content-start w-25">{{ item.projectHierarchyName }}</div>
										<div class="d-flex align-items-center justify-content-end w-25">
											<b-button
												v-if="item.exist"
												variant="outline-dark"
												style="border-radius: 4px !important; border: 1px solid #225cbd !important"
												@click.prevent="handleOpenModal(item)"
												v-b-tooltip.hover.left.html="`${FormMSG(16, 'Edit')}`"
											>
												<div class="d-flex justify-content-center align-items-center">
													<!-- <component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="18" :stroke-width="2.25" /> -->
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="18"
														height="18"
														color="#225CBD"
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														stroke-width="2"
														stroke-linecap="round"
														stroke-linejoin="round"
														class="lucide lucide-arrow-big-down-dash"
													>
														<path d="M15 5H9" />
														<path d="M15 9v3h4l-7 7-7-7h4V9h6z" />
													</svg>
												</div>
											</b-button>
											<b-button
												v-else
												variant="outline-dark"
												style="border-radius: 4px !important; border: 1px solid #47c7bf !important"
												@click.prevent="handleOpenModal(item)"
												v-b-tooltip.hover.left.html="`${FormMSG(17, 'Add')}`"
											>
												<div class="d-flex justify-content-center align-items-center">
													<component :is="getLucideIcon('Plus')" color="#47C7BF" :size="18" :stroke-width="2.25" />
												</div>
											</b-button>
										</div>
									</div>
								</div>
							</div>
						</template>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12">
						<div v-if="!_isActiveSearch" class="end-of-list p-2">{{ sizeOfUsers() }}</div>
					</b-col>
				</b-row>
				<b-modal
					header-class="header-class-modal-doc-package"
					:title="'Would you like to add this project'"
					class="modal-success"
					size="md"
					v-model="open"
					no-close-on-backdrop
					@ok="hideModal"
					ok-variant="success"
					ok-only
				>
					<div class="container-mobile animated fadeIn">
						<div class="content-detailsMyTimesheet-mobile">
							<b-form-input
								id="nameOfProject-api"
								v-model="nameOfProject"
								:readonly="true"
								@keyup.enter="handleAddProject"
								placeholder="Enter your project to search"
							></b-form-input>
						</div>
					</div>
					<template #modal-footer>
						<div class="w-100">
							<b-button variant="outline-dark" size="sm" class="float-left" @click="hideModal"> {{ FormMSG(12, 'Close') }} </b-button>
							<b-button
								variant="outline-primary"
								size="sm"
								class="float-right"
								@click="handleAddProject"
								:disabled="nameOfProject.length !== 0 ? false : true"
							>
								{{ action }}
							</b-button>
						</div>
					</template>
				</b-modal>
			</b-card>
		</b-col>
	</b-row>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getWorkDayProjectList, refreshWorkDaysProjectsList, createOrUpdateProjectFromWorkDays } from '@/cruds/fimalac.crud';
import CustomLoadingPlanning from '@/components/Plannings/Filter/CustomLoadingPlanning';
import { formatTextToSql } from '@/utils/utils';

export default {
	name: 'FimalacProject',
	mixins: [languageMessages, globalMixin],
	components: {
		CustomLoadingPlanning
	},
	data: () => ({
		search: '',
		nameOfProject: '',
		hierarchyWid: null,
		action: '',
		projects: [],
		globalProjects: [],
		isLoading: false,
		open: false,
		keyword: null,
		limit: 20,
		offset: 0,
		projectTotalRecent: 0,
		projectTotals: 0,
		_isActiveSearch: false,
		formatTextToSql
	}),
	watch: {
		search: {
			handler(newVal) {
				if (newVal.length <= 2) {
					this.projects = this.globalProjects;
					this._isActiveSearch = false;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		projects: {
			handler(newVal) {
				if (newVal.length !== 0) {
					setTimeout(() => {
						const listContent = document.getElementById('list-of-content');
						if (listContent !== undefined) {
							listContent.addEventListener('scroll', this.onScroll);
						}
					}, 100);
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	created() {
		this.initialize();
	},
	methods: {
		sizeOfUsers() {
			if (this.projectTotalRecent === this.projectTotals) {
				return (
					this.projectTotalRecent.toString().padStart(2, '0') +
					this.FormMSG(351, ' Of ') +
					this.projectTotals.toString().padStart(2, '0') +
					this.FormMSG(352, ' records ') +
					this.FormMSG(350, 'end of list')
				);
			}
			return (
				this.projectTotalRecent.toString().padStart(2, '0') +
				this.FormMSG(351, ' Of ') +
				this.projectTotals.toString().padStart(2, '0') +
				this.FormMSG(352, ' records ')
			);
		},
		async initialize() {
			this.isLoading = true;
			let arrays = [];
			let name = this.search.length !== 0 ? formatTextToSql(this.search.toString()) : null;
			let isActiveSearch = false;
			if (name !== null && name.length !== 0) {
				isActiveSearch = true;
			}
			for (let i = 0; i <= this.offset; i++) {
				let item = {
					keyword: name,
					limit: this.limit,
					offset: i
				};
				let result = await getWorkDayProjectList(item);
				arrays.push(...result);
			}
			this.projects = arrays;
			this.projectTotalRecent = arrays.length;
			if (isActiveSearch === false) {
				this.globalProjects = arrays;
				this.projectTotals = [...(await getWorkDayProjectList({ keywor: null, limit: null, offset: null }))].length;
			}
			this.isLoading = false;
		},
		handleOpenModal(item) {
			this.hierarchyWid = item.hierarchyWid;
			this.nameOfProject = item.projectHierarchyName;
			this.action = item.exist ? this.FormMSG(21, 'Edit') : this.FormMSG(13, 'Add');
			this.open = true;
		},
		hideModal() {
			this.open = false;
		},
		async handleRefresh() {
			try {
				this.isLoading = true;
				let result = await refreshWorkDaysProjectsList();
				if (result) {
					this.initialize();
					this.isLoading = false;
				} else {
					this.isLoading = false;
				}
			} catch (e) {
				const message = this.FormMSG(22, 'There is a server error');
				this.$bvToast.toast(message, {
					title: this.FormMSG(23, 'warning'),
					variant: 'warning',
					solid: true,
					toaster: 'b-toaster-top-center',
					autoHideDelay: 3000
				});
				this.isLoading = false;
			}
		},
		async handleAddProject() {
			try {
				if (this.hierarchyWid && this.nameOfProject.length !== 0) {
					this.isLoading = true;
					// let name = this.nameOfProject;
					let hierarchyWid = this.hierarchyWid;
					this.hideModal();
					let result = await createOrUpdateProjectFromWorkDays(hierarchyWid);
					if (result) {
						this.nameOfProject = '';
						this.hierarchyWid = null;
						let strTitle = this.FormMSG(14, 'Success');
						this.initialize();
						this.$bvToast.toast(this.FormMSG(15, 'Create with success'), {
							title: strTitle,
							variant: 'success',
							toaster: 'b-toaster-top-center',
							solid: true
						});
					} else {
						this.isLoading = false;
						this.initialize();
						let strTitle = this.FormMSG(160, 'Error');
						this.$bvToast.toast(this.FormMSG(170, 'Error to create project'), {
							title: strTitle,
							variant: 'error',
							toaster: 'b-toaster-top-center',
							solid: true
						});
					}
				}
			} catch (e) {
				const message = this.FormMSG(22, 'There is a server error');
				this.$bvToast.toast(message, {
					title: this.FormMSG(23, 'warning'),
					variant: 'warning',
					solid: true,
					toaster: 'b-toaster-top-center',
					autoHideDelay: 3000
				});
				this.isLoading = false;
			}
		},
		async handleSearch() {
			if (this.search.trim().length > 2) {
				this.isLoading = true;
				let name = formatTextToSql(this.search.toString());
				let item = {
					keyword: name,
					limit: null,
					offset: null
				};
				let result = await getWorkDayProjectList(item);
				this.projects = result;
				this.isLoading = false;
				this._isActiveSearch = true;
			}
		},
		async onScroll(event) {
			if (this.projectTotalRecent < this.projectTotals && event.target.scrollTop) {
				if (Math.ceil(event.target.scrollTop + event.target.clientHeight + 1) >= event.target.scrollHeight) {
					this.isLoading = true;
					this.offset += 1;
					let item = {
						keyword: this.keyword,
						limit: this.limit,
						offset: this.offset
					};
					let result = await getWorkDayProjectList(item);
					this.projects = [...this.projects, ...result];
					this.globalProjects = [...this.globalProjects, ...result];
					this.projectTotalRecent = this.globalProjects.length;
					this.isLoading = false;
				}
			}
		}
	}
};
</script>
<style scoped>
.gap-4 {
	gap: 4px !important;
}
.hover-active:hover {
	background-color: rgba(71, 199, 191, 0.5);
	cursor: pointer;
	padding: 5px !important;
}
.bg-beige {
	background-color: #f3f4f5 !important;
	font-weight: 700;
	text-transform: capitalize;
}
.end-of-list {
	color: rgba(6, 38, 62, 0.84);
	font-weight: 900;
	font-size: 0.95rem;
}
</style>
